//------------------------------------------------------------------//
// Fonts
//------------------------------------------------------------------//

// Didot 42
.font-didot--light {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: normal;
  font-weight: 300;
}

.font-didot--medium {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: normal;
  font-weight: 500;
}

.font-didot-medium-italic {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: italic;
  font-weight: 500;
}

.font-didot--bold {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: normal;
  font-weight: 700;
}

// Gotham
.font-gotham--extra-light {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 200;
}

.font-gotham--light {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 300;
}

.font-gotham--book {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.font-gotham--medium {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 500;
}

.font-gotham--bold {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

// Gotham
.gotham-book {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 400;
}

.gotham-medium {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 500;
}

.gotham-bold {
  font-family: "Gotham A", "Gotham B";
  font-style: normal;
  font-weight: 700;
}

// Didot 42
.didot-42-light {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: normal;
  font-weight: 500;
}

.didot-42-medium-italic {
  font-family: "Didot 42 A", "Didot 42 B";
  font-style: italic;
  font-weight: 500;
}

.text-didot-headline {
  @extend .didot-42-light;
  margin-bottom: 10px;
  color: #000000;
  font-size: 32px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

.text-headline {
  @extend .font-gotham--bold;
  margin: 0;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;

  &--lg {
      font-size: 24px !important;
      line-height: 30px !important;
  }

  &--md {
      font-size: 20px !important;
      line-height: normal !important;
  }

  &--sm {
      font-size: 12px !important;
      line-height: normal !important;
  }

  &--xs {
      font-size: 10px !important;
      line-height: normal !important;
  }
}

.text-body {
  @extend .font-gotham--book;
  font-size: 15px;
  line-height: 23px;

  &--lg {
      font-size: 18px;
      line-height: 26px;
  }

  &--sm {
      font-size: 14px;
      line-height: 21px;
  }

  &--xs {
      font-size: 12px;
      line-height: 18px;
  }

  &--text-link,

  a {
      @extend .font-gotham--bold;
      color: #000000;

      &:link {
          text-decoration: none;
      }

      &:hover {
          color: red;
          text-decoration: underline;
      }
  }

  &.text-white a {
      color: #fff;
      text-decoration: underline;
  }
}

.text {

  // Color modifiers
  &--white {
      color: #ffffff !important;
  }
  &--black {
      color: #000000 !important;
  }
  &--red {
      color: red !important;
  }

  &--grey {
      color: #999 !important;
  }

  // Casing modifiers
  &--upper {
      text-transform: uppercase !important;
  }
  &--center {
      text-align: center !important;
  }

  // Style modifiers
  &--bold {
      @extend .font-gotham--bold;
  }

  &--decoration-none {
      text-decoration: none;
  }
}


//------------------------------------------------------------------//
// Title Styles
//------------------------------------------------------------------//

.title-xl {
  @extend .didot-42-light;
  color: #ffffff;
  font-size: 80px;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (max-width: 760px) {
      font-size: 60px;
  }
}

.title-sm {
  @extend .gotham-medium;
  color: #000000;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 2px;
  text-transform: uppercase;
}

//------------------------------------------------------------------//
// Text Styles
//------------------------------------------------------------------//

p.body-text,
.body-text {
  @extend .gotham-book;
  color: #000000;
  font-size: 14px;
  line-height: 26px;

  a {
  @extend .gotham-medium;
  color: #000000;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

&.body-text-sm {
  font-size: 12px;
  line-height: 18px;
}

&.body-text-bold {
  @extend .gotham-medium;
  }

  &.body-text-white {
      color: #ffffff;
  }
}

a.text-link {
  @extend .gotham-book;
  color: #000000;
  font-size: 13px;
  letter-spacing: 2px;
  text-transform: uppercase;
  text-decoration: underline;

  &--sm {
      font-size: 12px;
      letter-spacing: normal;
  }
}

//------------------------------------------------------------------//
// Basisc Text Styles
//------------------------------------------------------------------//

.text-black {
  color: #000000;
}

.text-red {
  color: red;
}

.text-white {
  color: #ffffff;
}

.text-center {
  text-align: center;
}