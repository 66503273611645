@import url('https://cloud.typography.com/7397092/7220792/css/fonts.css');
@import './vars.scss';
@import './typography.scss';

#designtool {
    position: fixed;
    top: 0;
    left: 0;
    font-family: "Gotham A", "Gotham B";
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
    width: 100vw;
    height: 100vh;
    overflow-y: hidden;
    background-color: rgba(0, 0, 0, .3);

    // BUTTON RESET
    button,
    button:focus,
    button:hover {
        outline: none;
        // border: none;
    }

    strong {
        font-weight: bold;
    }

    .rounded-sm {
        border-radius: 0.325rem;
    }

    .txt-sm {
        font-family: "Gotham A", "Gotham B";
        font-style: normal;
        font-weight: 500;
        font-size: .85rem;
        line-height: 12px;
    }

    .font-md {
        font-weight: 500;
    }

    .debug {
        background-color: rgba(0, 0, 0, .3);
        border: 1px solid rgba(0, 0, 0, .5);
    }

    .atc-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 50px 0 30px 16.5%; // Matches column of photo gallery items
    }

    .mt-xs {
        margin-top: 8px;
    }

    .product-render canvas {
        width: 100%;
        height: auto;
    }

    .app {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;
        min-height: 700px;
        max-height: 800px;
        max-width: 1280px;
        overflow-y: hidden;
        padding-top: 20px;
        background-color: #fff;
        border: 2px solid black;
        padding: 10px;

        &--loading {
            justify-content: center;
        }
    }

    .app-loader {
        margin: 0 auto;
        justify-self: center;
        align-self: center;
    }

    .photo-gallery__menu__item .thumb-hover {
        transition: opacity 0.2s ease;
        border-width: 4px;
        opacity: 0;
    }

    .photo-gallery__menu__item.active .thumb-hover {
        opacity: 1;
    }

    .mobile-bag-info {
        display: none;
    }

    .atc-nav .btn--customize {
        display: none;
    }

    .step-nav__item__button--close .icon.icon--arrow-down {
        display: none;
    }

    .atc-nav button.btn--customize,
    .atc-nav button.btn--add-to-cart,
    .atc-nav button.btn--customize:hover,
    .atc-nav button.btn--add-to-cart:hover {
        border: 1px solid black !important;
    }

    @media (max-width: $MOBILE_W) {
        .app {
            flex-direction: column;
            max-height: none;
            height: auto;
        }

        .step-nav__item__button--close .icon.icon--hamburger {
            display: none;
        }


        .step-nav__item__button--close .icon.icon--arrow-down {
            display: inline;
            transform: rotate(90deg);
        }

        .step-nav__item__button--close .label {
            opacity: 0;
        }

        .product-form {
            flex: 1 0 100%;
        }

        .btn-save {
            margin: 0;
            flex-direction: column;
            align-items: center;

            svg {
                margin-bottom:5px;
            }
        }

        .btn--add-to-cart {
            margin-left: 0 !important;
            margin-right: 0 !important;
        }

        .form-group--opened {
            flex: 1 0 auto;
        }

        // Style adjustments for mobile design tool V2

        .product-form__bar {
            border-bottom: none;
        }

        .photo-gallery__menu--desktop {
            display: none !important;
        }

        .product-preview {
            margin-bottom: 20px;
        }

        .mobile-bag-info {
            display: block;
        }

        .product-form__header .bag-info {
            display: none;
        }

        .product-form__header .atc-nav {
            position: fixed;
            justify-content: center;
            bottom: 0;
            left: 0;
            padding: 7px 20px 30px;
            z-index: 97;
            width: 100vw;
            border-top: 1px solid black;
            background-color: #fff;

            .btn-save.btn--save,
            .btn-save.btn--share {
                display: none;
            }

            .btn--customize {
                display: block;
            }

            .btn--customize,
            .btn--add-to-cart {
                border-radius: 25px;
                padding: 11px 10px;
                max-width: 50%;
                font-size: 12px;
                letter-spacing: 1px;
            }
        }

        #design-tool-gallery .flickity-page-dots {
            display: block !important;
            position: relative !important;
            bottom: auto;
            padding: 10px 10px;
        }
    }
}



#app-product-form-container {
    position: absolute;
    bottom: 0;
    z-index: 20;
    width: 100%;
    opacity: 0;

    &.animate {
        transition: transform .5s ease-out;
    }

    // lg
    @media(min-width: 1024px) {
        bottom: auto;
        right: 0;
        top: 0;
        width: 33.3333%;
        height: 100%;
    }

    @media(max-width: 1024px) {
        &.focus-blurred {
            transform: translateY(100%) !important;
        }
    }
}

.slide-out-container {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 50;
    width: 100%;
    box-shadow: 0px 9px 20px 0px rgba(0, 0, 0, 0.5);

    transition: transform .3s ease-out;
    transform: translateY(100%);

    &.is-open {
        transform: translateY(0);
    }

    // lg
    @media(min-width: 1024px) {
        box-shadow: none;
        bottom: auto;
        top: 0;
        right: 0;
        transform: translateX(100%);
        width: 33.333333%;
        height: 100vh;
    }
}

.thumbnail-list {
    display: grid;
    grid-gap: 1rem;
    gap: 1rem;
    grid-auto-flow: column;
    grid-auto-columns: 75px;
    padding: 0 1rem 20px 1rem;

    // lg
    @media(min-width: 1024px) {
        grid-auto-flow: row;
        grid-template-columns: repeat(4, minmax(0, 1fr));
        padding-bottom: 100px;
    }

    // xl
    @media(min-width: 1280px) {
        grid-template-columns: repeat(4, minmax(0, 1fr));
    }
}

